<template>
  <div
    class="text-faded q-px-lg text-center text-caption q-mb-xl"
    v-html="text"
  />
</template>

<script>

export default {
  name: 'MTerms',
  props: {
    privacyURL: {
      type: String,
      default: 'https://www.mobilleo.com/legal/privacy-policy'
    },
    termsURL: {
      type: String,
      default: 'https://www.mobilleo.com/legal/terms'
    },
    nationalRailTravelConditionsURL: {
      type: String,
      default: 'https://assets.ctfassets.net/e8xgegruud3g/LVkecgKuE5yUvcBW2mRbQ/29cd3a0cf9f46ead52f473f7f7e9b4e6/National_Rail_Conditions_of_Travel.pdf'
    },
    showNationalrail: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text () {
      let text = this.$t('mobilleo_privacy_and_terms', { privacyURL: this.privacyURL, termsURL: this.termsURL })

      if (this.showNationalrail) {
        text = text + ', as well as ' + this.$t('nationalrail_ticket_and_travel_conditions', { travelConditionsURL: this.nationalRailTravelConditionsURL })
      }

      return text
    }
  }
}
</script>
